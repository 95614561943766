<template>
  <div class="tw-flex tw-w-full tw-flex-col setting">
    <v-tabs
      fixed-tabs
      align-with-title
      v-model="tab"
      class="tw-pb-5 lg:tw-pb-0"
      :slider-color="companyData.companyColor"
      :color="companyData.companyColor"
    >
      <v-tab class="tw-justify-start switch-header tw-p-1">
        <v-icon class="tw-mr-2">mdi-account</v-icon>
        Profile
      </v-tab>
      <v-tab class="tw-justify-start switch-header tw-p-1">
        <icons
          class="tw-mr-2"
          name="key"
          :color="tab === 1 ? companyData.companyColor : '#989CA0'"
        />
        Account Details
      </v-tab>
      <!--    <v-tab class="tw-justify-start switch-header tw-p-1">-->
      <!--      <icons class="tw-mr-2" name="switch"  :color="tab === 2 ? companyData.companyColor : '#989CA0'"/>-->
      <!--      Deactivate Account-->
      <!--    </v-tab>-->
      <v-tab class="switch-header tw-p-1 tw-justify-start">
        <icons
          class="tw-mr-2"
          name="change-password"
          :color="tab === 2 ? companyData.companyColor : '#989CA0'"
        />
        Change Password
      </v-tab>
      <v-tab-item
        class="tw-flex tw-w-full tw-flex-col lg:tw-px-28 lg:tw-pt-20 tw-pt-10"
      >
        <v-tabs
          fixed-tabs
          align-with-title
          v-model="profileTab"
          class="tw-pb-3 lg:tw-pb-0"
          hide-slider
          :color="companyData.companyColor"
        >
          <v-tab class="switch-header tw-p-1 tw-justify-start">
            Brand Identity
          </v-tab>
          <v-tab class="switch-header tw-p-1 tw-justify-start"> Address </v-tab>
          <v-tab class="switch-header tw-p-1 tw-justify-start"> Contact </v-tab>
          <v-tab class="switch-header tw-p-1 tw-justify-start">
            Verification Documents
          </v-tab>
          <v-tab class="switch-header tw-p-1 tw-justify-start">
            Deductions
          </v-tab>
          <v-tab-item
            class="
              tw-flex tw-w-full tw-flex-col
              tw-px-5 lg:tw-px-28 lg:tw-pt-20
              tw-pt-10 tw-bg-white tw-pb-5 tw-rounded-md
            "
          >
            <div class="tw-flex tw-flex-col lg:tw-flex-row tw-w-full lg:tw-justify-between">
              <div class="tw-w-full lg:tw-w-5/12">
                <p class="desc-text">Company Logo</p>
                <button
                  class="button-logo-input"
                  type="button"
                  :class="image ? 'border-0' : ''"
                >
                  <v-progress-circular
                    :size="100"
                    :width="3"
                    :color="companyData.companyColor"
                    indeterminate
                    style="position: absolute"
                    v-if="logoImageLoading"
                  ></v-progress-circular>
                  <img
                    :src="companyData.companyLogo"
                    alt=""
                    class="button-logo-img"
                    v-if="companyData.companyLogo"
                  />
                  <img
                    :src="image"
                    alt=""
                    class="button-logo-img"
                    v-else-if="logoImage"
                  />
                  <img
                    src="@/assets/addImage.svg"
                    alt=""
                    class="button-logo-input-img"
                    v-else
                  />
                </button>
                <input
                  type="file"
                  ref="logoImage"
                  accept="image/*"
                  style="display: none"
                  @change="onLogoImagePicked"
                />
              </div>
              <div class="tw-w-full lg:tw-w-6/12 lg:tw-pl-3 tw-mt-5 lg:tw-mt-0">
                <p>Company Brand Color</p>
                <v-tooltip bottom :color="companyData.companyColor">
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      v-bind="attrs"
                      v-on="on"
                      class="color-picker"
                      :style="{ background: `${companyData.companyColor}` }"
                    ></div>
                  </template>
                  <label>Light colors are not advisable</label>
                </v-tooltip>
              </div>
            </div>

            <v-row class="tw-flex-col lg:tw-flex-row tw-mt-10">
              <v-col sm="12" lg="6">
                <p class="desc-text">Company Name</p>
                <v-text-field
                  v-model="companyData.companyName"
                  solo
                  hide-details
                  placeholder="RoadPadi"
                  readonly
                ></v-text-field>
              </v-col>

              <v-col sm="12" lg="6">
                <p class="desc-text">Company Website</p>
                <v-text-field
                  v-model="companyData.website"
                  solo
                  hide-details
                  placeholder="roadpadi.com"
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="tw-flex-col lg:tw-flex-row lg:tw-pt-10">
              <v-col sm="12">
                <p class="desc-text">Vision Statement</p>
                <v-textarea
                  rows="5"
                  v-model="companyData.visionStatement"
                  solo
                  hide-details
                  placeholder="To grow your business"
                  type="text"
                  readonly
                ></v-textarea>
              </v-col>
            </v-row>
<!--            <div class="tw-flex tw-w-full tw-justify-end tw-mt-10 tw-py-3">-->
<!--              <v-btn-->
<!--                :style="{ background: companyData.companyColor }"-->
<!--                text-->
<!--                class="save"-->
<!--                >Save</v-btn-->
<!--              >-->
<!--            </div>-->
          </v-tab-item>
          <v-tab-item
            class="
              tw-flex tw-w-full tw-flex-col
              tw-px-5
              lg:tw-px-28 lg:tw-pt-20
              tw-pt-10 tw-bg-white tw-pb-5 tw-rounded-md

            "
          >
            <v-row class="form">
              <v-col lg="6" sm="12" cols="12" class="mb-md-5">
                <ValidationProvider
                  name="Country"
                  rules="required"
                  v-slot="{ classes, errors }"
                >
                  <label class="label">Country</label>
                  <div class="tw-pt-1 mb-1 mt-2" :class="classes">
                    <v-combobox
                      :items="countries"
                      placeholder="Choose Country"
                      append-icon="mdi-chevron-down"
                      item-text="name"
                      item-value="name"
                      v-model="companyData.address.country"
                      @change="getState()"
                      solo
                      hide-details
                      readonly
                    ></v-combobox>
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </v-col>

              <v-col lg="6" sm="12" cols="12" class="mb-md-5">
                <ValidationProvider
                  name="State"
                  rules="required"
                  v-slot="{ classes, errors }"
                >
                  <label class="label">State</label>
                  <div class="tw-pt-1 mb-1 mt-2" :class="classes">
                    <v-combobox
                      :items="states"
                      placeholder="Choose State"
                      append-icon="mdi-chevron-down"
                      item-text="name"
                      item-value="name"
                      v-model="companyData.address.state"
                      @change="getCity()"
                      solo
                      hide-details
                      readonly
                    ></v-combobox>
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </v-col>

              <v-col lg="6" sm="12" cols="12" class="mb-md-5">
                <ValidationProvider
                  name="City/Town"
                  rules="required"
                  v-slot="{ classes, errors }"
                >
                  <label class="label">City/Town</label>
                  <div class="tw-pt-1 mb-1 mt-2" :class="classes">
                    <v-combobox
                      :items="cities"
                      placeholder="Choose State"
                      append-icon="mdi-chevron-down"
                      v-model="companyData.address.city"
                      @change="getCity()"
                      solo
                      hide-details
                      readonly
                    ></v-combobox>
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </v-col>

              <v-col lg="6" sm="12" cols="12" class="mb-md-5">
                <ValidationProvider
                  name="Street Name"
                  rules="required"
                  v-slot="{ classes, errors }"
                >
                  <label class="label">Street Name</label>
                  <div class="tw-pt-1 mb-1 mt-2" :class="classes">
                    <v-text-field
                      placeholder="Street Name"
                      solo
                      hide-details
                      type="text"
                      readonly
                      v-model="companyData.address.streetName"
                      required
                    ></v-text-field>
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </v-col>

              <v-col lg="6" sm="12" cols="12" class="mb-md-5">
                <ValidationProvider
                  name="House Number"
                  rules="required"
                  v-slot="{ classes, errors }"
                >
                  <label class="label">House Number</label>
                  <div class="tw-pt-1 mb-1 mt-2" :class="classes">
                    <v-text-field
                      placeholder="House Number"
                      solo
                      hide-details
                      type="Number"
                      readonly
                      v-model="companyData.address.houseNumber"
                      required
                    ></v-text-field>
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item
            class="
              tw-flex tw-w-full tw-flex-col
             tw-px-5 lg:tw-px-28 lg:tw-pt-20
              tw-pt-10 tw-bg-white tw-pb-5 tw-rounded-md
            "
          >
            <v-row class="form">
              <v-col lg="6" sm="12" cols="12" class="mb-md-5">
                <ValidationProvider
                  name="Primary Phone Number"
                  rules="required"
                  v-slot="{ classes, errors }"
                >
                  <label class="label">Primary Phone Number</label>
                  <div class="tw-pt-1 mb-1 mt-2" :class="classes">
                    <v-text-field
                      placeholder="Primary Phone Number"
                      solo
                      hide-details
                      type="Number"
                      readonly
                      v-model="companyData.firstContactPhoneNumber"
                      required
                    ></v-text-field>
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </v-col>
              <v-col lg="6" sm="12" cols="12" class="mb-md-5">
                <ValidationProvider
                  name="Primary Email"
                  rules="required"
                  v-slot="{ classes, errors }"
                >
                  <label class="label">Primary Email</label>
                  <div class="tw-pt-1 mb-1 mt-2" :class="classes">
                    <v-text-field
                      placeholder="Primary Email"
                      solo
                      hide-details
                      type="email"
                      readonly
                      v-model="companyData.companyEmail"
                      required
                    ></v-text-field>
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </v-col>

              <v-col lg="6" sm="12" cols="12" class="mb-md-5">
                <ValidationProvider
                  name="Secondary Phone Number"
                  rules="required"
                  v-slot="{ classes, errors }"
                >
                  <label class="label">Secondary Phone Number</label>
                  <div class="tw-pt-1 mb-1 mt-2" :class="classes">
                    <v-text-field
                      placeholder="Secondary Phone Number"
                      solo
                      hide-details
                      type="Number"
                      readonly
                      v-model="companyData.secondContactPhoneNumber"
                      required
                    ></v-text-field>
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </v-col>
              <v-col lg="6" sm="12" cols="12" class="mb-md-5">
                <ValidationProvider
                  name="Secondary Email"
                  rules="required"
                  v-slot="{ classes, errors }"
                >
                  <label class="label">Secondary Email</label>
                  <div class="tw-pt-1 mb-1 mt-2" :class="classes">
                    <v-text-field
                      placeholder="Secondary Email"
                      solo
                      hide-details
                      readonly
                      type="email"
                      v-model="companyData.secondaryEmail"
                      required
                    ></v-text-field>
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </v-col>
            </v-row>
            <div class="tw-hidden tw-w-full tw-justify-end tw-mt-10 tw-py-3">
              <v-btn
                :style="{ background: companyData.companyColor }"
                text
                class="save"
                >Save</v-btn
              >
            </div>
          </v-tab-item>

          <v-tab-item
            class="
              tw-flex tw-w-full tw-flex-col
             tw-px-5 lg:tw-px-28 lg:tw-pt-20
              tw-pt-10 tw-bg-white tw-pb-5 tw-rounded-md
            "
          >
            <v-row class="form">
              <v-col lg="6" sm="12" cols="12" class="mb-md-5">
                <p class="desc-text">CAC Certificate</p>
                <img
                  :src="companyData.cacCertificate"
                  alt=""
                  class="view-cert"
                  v-if="companyData.cacCertificate"
                />
              </v-col>
              <v-col lg="6" sm="12" cols="12" class="mb-md-5">
                <p class="desc-text">Rep ID Card</p>
                <img
                  :src="companyData.companyRepresentativeIdCard"
                  alt=""
                  class="view-cert"
                  v-if="companyData.companyRepresentativeIdCard"
                />
              </v-col>
            </v-row>
            <v-row class="form">
              <v-col lg="6" sm="12" cols="12" class="mb-md-5">
                <ValidationProvider
                  name="CAC Registration Number"
                  rules="required"
                  v-slot="{ classes, errors }"
                >
                  <label class="label">CAC Registration Number</label>
                  <div class="tw-pt-1 mb-1 mt-2" :class="classes">
                    <v-text-field
                      placeholder="CAC Registration Number"
                      solo
                      hide-details
                      type="text"

                      readonly
                      v-model="companyData.cacRegistrationNumber"
                      required
                    ></v-text-field>
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </v-col>
              <v-col lg="6" sm="12" cols="12" class="mb-md-5">
                <ValidationProvider
                  name="Tax ID Number"
                  rules="required"
                  v-slot="{ classes, errors }"
                >
                  <label class="label">Tax ID Number</label>
                  <div class="tw-pt-1 mb-1 mt-2" :class="classes">
                    <v-text-field
                      placeholder="Tax ID Number"
                      solo
                      readonly
                      hide-details
                      type="text"
                      disabled
                      v-model="companyData.taxId"
                      required
                    ></v-text-field>
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item
              class="
              tw-flex tw-w-full tw-flex-col
              tw-px-5 lg:tw-px-28 lg:tw-pt-20
              tw-pt-10 tw-bg-white tw-pb-28 tw-rounded-md
            ">
            <div class="tw-flex tw-w-full tw-flex-col">
              <h5><b>Booking:</b> Agreed amount RoadPadi deduct for successful Booking</h5>
              <div class="tw-flex tw-flex-col tw-py-5">
                <label class="label tw-pb-3">Deduction Type</label>
                <v-text-field solo
                              hide-details disabled placeholder="FIXED" v-model="companyData.deductionType"></v-text-field>
              </div>
              <div class="tw-flex tw-flex-col tw-py-5">
                <label class="label tw-pb-3">Deduction Amount</label>
                <v-text-field solo
                              hide-details disabled placeholder="150" v-model=" companyData.deductionAmount ">
                  <template #prepend-inner>&#8358;</template>
                </v-text-field>
              </div>
            </div>

            <div class="tw-flex tw-w-full tw-flex-col tw-mt-10">
              <h5><b>Pos Terminal:</b> Agreed amount RoadPadi deduct for Pos repayment</h5>
              <div class="tw-flex tw-flex-col tw-py-5">
                <label class="label tw-pb-3">Deduction Type</label>
                <v-text-field solo
                              hide-details disabled placeholder="FIXED" v-model="companyData.deductionTypePerBookingForPos"></v-text-field>
              </div>
              <div class="tw-flex tw-flex-col tw-py-5">
                <label class="label tw-pb-3">Deduction Amount</label>
                <v-text-field solo
                              hide-details disabled placeholder="150" v-model=" companyData.deductibleAmountPerBookingForPos ">
                  <template #prepend-inner>&#8358;</template>
                </v-text-field>
              </div>
            </div>

          </v-tab-item>
        </v-tabs>
      </v-tab-item>

      <v-tab-item
        class="
          tw-flex tw-w-full tw-flex-col
         tw-px-5 lg:tw-pt-20
          tw-pt-10 tw-items-center tw-bg-white
        "
      >
        <v-card flat class="account-details-card tw-py-10">
          <v-row class="form tw-flex tw-flex-col lg:tw-flex-row">
            <v-col sm="12" class="mb-md-4">
              <ValidationProvider
                name="Bank Name"
                rules="required"
                v-slot="{ classes, errors }"
              >
                <label class="label">Bank Name</label>
                <div class="tw-pt-1 mb-1 mt-2" :class="classes">
                  <v-combobox
                    placeholder="Sterling Bank"
                    solo
                    hide-details
                    type="text"
                    v-model="companyData.accountDetail.bankName"
                    required
                    class="tw-mt-2"
                    :items="banks"
                    readonly
                    append-icon="mdi-chevron-down"
                  ></v-combobox>
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </v-col>

            <v-col sm="12" class="mb-md-4">
              <ValidationProvider
                name="Account Type"
                rules="required"
                v-slot="{ classes, errors }"
              >
                <label class="label">Account Type</label>
                <div class="tw-pt-1 mb-1 mt-2" :class="classes">
                  <v-combobox
                    placeholder="Current Account"
                    solo
                    hide-details
                    type="text"
                    v-model="companyData.accountDetail.accountType"
                    :items="accountTypes"
                    readonly
                    required
                    append-icon="mdi-chevron-down"
                  ></v-combobox>
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </v-col>

            <v-col sm="12" class="mb-md-4">
              <ValidationProvider
                name="Account Number"
                rules="required"
                v-slot="{ classes, errors }"
              >
                <label class="label">Account Number</label>
                <div class="tw-pt-1 mb-1 mt-2" :class="classes">
                  <v-text-field
                    placeholder="0142140595"
                    solo
                    hide-details
                    readonly
                    type="Number"
                    v-model="companyData.accountDetail.accountNumber"
                    required
                    class="tw-mt-2"
                  ></v-text-field>
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </v-col>

            <v-col sm="12" class="mb-md-4">
              <ValidationProvider
                name="Account Name"
                rules="required"
                v-slot="{ classes, errors }"
              >
                <label class="label">Account Name</label>
                <div class="tw-pt-1 mb-1 mt-2" :class="classes">
                  <v-text-field
                    placeholder="Aramide Bhadmus"
                    solo
                    readonly
                    hide-details
                    type="text"
                    v-model="companyData.accountDetail.accountName"
                    required
                  ></v-text-field>
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </v-col>
          </v-row>
<!--          <div class="tw-flex tw-w-full tw-justify-end tw-mt-10 tw-py-3">-->
<!--            <v-btn-->
<!--              :style="{ background: companyData.companyColor }"-->
<!--              text-->
<!--              class="save"-->
<!--              >Save</v-btn-->
<!--            >-->
<!--          </div>-->
        </v-card>
      </v-tab-item>

      <!--    <v-tab-item   class="tw-flex tw-w-full tw-flex-col lg:tw-px-28 lg:tw-pt-20 tw-pt-10" >-->
      <!--      <div class="deactivate-account-card tw-px-10 tw-pb-5">-->
      <!--      <v-row class="tw-flex-col lg:tw-flex-row tw-mt-10">-->
      <!--        <v-col sm="12" >-->
      <!--          <p class="desc-text">Why do you want to Deactivate your account?</p>-->
      <!--          <v-textarea rows="5" solo hide-details placeholder="Reasons" ></v-textarea>-->
      <!--        </v-col>-->
      <!--      </v-row>-->
      <!--      <v-row class="tw-flex-col lg:tw-flex-row lg:tw-pt-10">-->
      <!--        <v-col sm="12" lg="6">-->
      <!--          <p class="desc-text">Email</p>-->
      <!--          <v-text-field solo hide-details placeholder="sorry@example.com"  type="email"></v-text-field>-->
      <!--        </v-col>-->
      <!--        <v-col sm="12" lg="6">-->
      <!--          <p class="desc-text">Password</p>-->
      <!--          <v-text-field solo hide-details placeholder="Password"  type="password"></v-text-field>-->
      <!--        </v-col>-->
      <!--      </v-row>-->
      <!--      <div class="tw-flex tw-w-full tw-justify-end tw-mt-10 tw-py-3">-->
      <!--        <v-btn text class="save" style="font-weight: bold" :style="{background:companyData.companyColor}">Deactivate Account</v-btn>-->
      <!--      </div>-->
      <!--      </div>-->
      <!--    </v-tab-item>-->

      <v-tab-item
        class="tw-flex tw-w-full tw-justify-center lg:tw-pt-20 tw-pt-10"
      >
        <div class="
            tw-flex tw-justify-center tw-w-full
            change-password-card
            tw-bg-white tw-py-10 tw-px-5
          "
        >
          <validation-observer ref="observer" v-slot="{ handleSubmit }" class="tw-w-full">
            <form @submit.prevent="handleSubmit(changePassword)">
              <div class="tw-w-full tw-flex-col">
                <v-col sm="12">
                  <ValidationProvider
                    name="Old Password"
                    rules="required"
                    v-slot="{ classes, errors }"
                  >
                    <p class="desc-text">Old Password</p>
                    <v-text-field
                        :color="companyData.companyColor"
                      solo
                      hide-details
                      placeholder="Old password"
                      :append-icon="oldPasswordValue ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="() => (oldPasswordValue = !oldPasswordValue)"
                      :type="oldPasswordValue ? 'text' : 'password'"
                      v-model="passwordData.oldPassword"

                    ></v-text-field>
                    <span class="err">{{ errors[0] }}</span>
                  </ValidationProvider>
                </v-col>
                <v-col sm="12">
                  <ValidationProvider
                    name="New Password"
                    rules="required"
                    v-slot="{ classes, errors }"
                  >
                    <p class="desc-text">New Password</p>
                    <v-text-field
                        :color="companyData.companyColor"
                      solo
                      hide-details
                      placeholder="New password"
                      v-model="passwordData.newPassword"
                      :append-icon="newPasswordValue ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="() => (newPasswordValue = !newPasswordValue)"
                      :type="newPasswordValue ? 'text' : 'password'"
                    ></v-text-field>
                    <span class="err">{{ errors[0] }}</span>
                  </ValidationProvider>
                </v-col>
                <v-col sm="12">
                  <ValidationProvider
                    name="Confirm Password"
                    rules="required"
                    v-slot="{ classes, errors }"
                  >
                    <p class="desc-text">Confirm Password</p>
                    <v-text-field
                        :color="companyData.companyColor"
                      solo
                      hide-details
                      placeholder="Confirm password"
                      v-model="passwordData.confirmPassword"
                      :append-icon="confirmPasswordValue ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="() => (confirmPasswordValue = !confirmPasswordValue)"
                      :type="confirmPasswordValue ? 'text' : 'password'"
                    ></v-text-field>
                    <span class="err">{{ errors[0] }}</span>
                  </ValidationProvider>
                </v-col>
                <div class="tw-flex tw-w-full tw-justify-end tw-mt-10 tw-py-3">
                  <v-btn
                    text
                    class="save"
                    type="submit"
                    style="font-weight: bold"
                    :style="{ background: companyData.companyColor }"
                    :loading="ispasswordUpdateLoading"
                    >Change Password</v-btn
                  >
                </div>
              </div>
            </form>
          </validation-observer>
        </div>
      </v-tab-item>
    </v-tabs>
    <modal />
    <ColorModal
      :dialog="dialog"
      @close="toggleModal"
      @changeTitle="ChangeT($event)"
    />
  </div>
</template>

<script>
import Icons from "@/components/reuseables/Icons";
import { uploadToCloudinary } from "@/services/api/APIService";
import Modal from "@/components/reuseables/Modal";
import ColorModal from "@/components/reuseables/ColorPickerModal";
import {
  getAllCitiesInAState,
  getAllCountries,
  getAllStatesByCountry,
  updatePassword,
} from "@/services/api/APIService";
import Lga from "../../assets/lga";
import {mapGetters} from "vuex";
export default {
  name: "AccountSetting",
  components: { Modal, Icons, ColorModal },
  data() {
    return {
      tab: null,
      logoImage: "",
      image: "",
      passwordData: {},
      ispasswordUpdateLoading: false,
      logoImageLoading: false,
      color: "#1E5770",
      profileTab: null,
      dialog: false,
      title: "",
      getCountriesLoading: false,
      getStatesLoading: false,
      getCitiesLoading: false,
      countries: [],
      states: [],
      cities: [],
      accountTypes: ["Savings", "Current", "Universal"],
      banks: [
        "GTBank",
        "UBA",
        "Zenith Bank",
        "Union Bank",
        "Fidelity Bank",
        "Sterling bank",
        "Stanbic IBTC",
        "Eco Bank",
        "Kuda bank",
      ],
      oldPasswordValue : false,
      newPasswordValue: false,
      confirmPasswordValue: false
    };
  },
  watch: {},
  computed: {
    preset() {
      return process.env.VUE_APP_CLOUDINARY_UPLOAD_PRESET;
    },
    ...mapGetters('companyProfile',['companyData'])
  },
  methods: {
    toggleModal() {
      this.dialog = !this.dialog;
    },
    ChangeT(title) {
      this.title = title;
      this.companyData.companyColor = title;
      this.dialog = false;
    },
    onLogoFile() {
      this.$refs.logoImage.click();
    },
    async onLogoImagePicked(event) {
      const files = event.target.files[0];
      try {
        this.logoImageLoading = true;
        const fileReader = new FileReader();
        fileReader.addEventListener("load", async () => {
          this.companyData.logo = "";
          this.image = fileReader.result;
          this.logoImage = files.name;
          let newData = {
            file: this.image,
            upload_preset: this.preset,
          };
          const response = await uploadToCloudinary(newData);
          this.companyData.logo = response.data.url;

          this.logoImageLoading = false;
        });
        fileReader.readAsDataURL(files);
      } catch (err) {
        console.log(err.response);
      }
    },
    async getCountries() {
      try {
        this.getCountriesLoading = true;
        const response = await getAllCountries();
        this.countries = response.data.data;
        this.getCountriesLoading = false;
      } catch (err) {
        console.log(err.response);
        this.getCountries = false;
      }
    },
    async getState() {
      try {
        this.getStatesLoading = true;
        let data = {
          country: this.companyData.address.country,
        };
        if (this.companyData.address.country.name) {
          data.country = this.companyData.address.country.name;
        }
        const response = await getAllStatesByCountry(data);
        this.states = response.data.data.states;
        this.getStatesLoading = false;
      } catch (err) {
        console.log(err.response);
      }
    },
    async getCity() {
      if (this.companyData.address.country.name === "Nigeria") {
        this.cities = Lga[this.companyData.address.state.name];
      } else {
        try {
          this.getCitiesLoading = true;
          let data = {
            country: this.companyData.address.country,
            state: this.companyData.address.state,
          };
          if (this.companyData.address.country.name) {
            data.country = this.companyData.address.country.name;
          }
          if (this.companyData.address.state.name) {
            data.state = this.companyData.address.state.name;
          }
          const response = await getAllCitiesInAState(data);
          this.cities = response.data.data;
          this.getCitiesLoading = false;
        } catch (err) {
          console.log(err.response);
          this.cities = [];
          this.getCitiesLoading = false;
        }
      }
    },
    async changePassword() {
      if (this.passwordData.newPassword === this.passwordData.confirmPassword) {
        this.ispasswordUpdateLoading = true;
        this.passwordData.email = this.companyData.companyEmail;
        await updatePassword(this.passwordData)
          .then((res) => {
            console.log(res.data);
            this.passwordData = {};
            this.ispasswordUpdateLoading = false;
            window.location.reload()
          })
          .catch((err) => {
            this.ispasswordUpdateLoading = false;
            this.$displaySnackbar({
              message: err.toString(),
              success: false,
            });
          });
      } else {
        this.$displaySnackbar({
          message: "New and Confirm Password do not match",
          success: false,
        });
      }
    },
  },
  mounted() {
    this.getCountries();
  },
  created() {

  },
};
</script>

<style scoped lang="scss">
.switch-header {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-transform: none;
}

::v-deep
  .v-tabs:not(.v-tabs--vertical):not(.v-tabs--right)
  > .v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes)
  .v-slide-group__prev {
  @media screen and (max-width: 768px) {
    display: none !important;
  }
}

.v-tabs--fixed-tabs > .v-tabs-bar .v-tab {
  @media screen and (max-width: 768px) {
    width: max-content !important;
    margin-right: 1rem !important;
  }
}

::v-deep .v-slide-group__content {
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
}
.desc-text {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
  color: #4f4f4f;
  margin-bottom: 20px;
}
.save {
  background: #004aad;
  border: 1px solid rgba(229, 229, 229, 0.1);
  box-sizing: border-box;
  border-radius: 10px;
  min-width: 135px !important;
  color: #ffffff;
  text-transform: none;
  height: 50px !important;
}

.card {
  background: rgba(253, 255, 252, 0.06);
  border: 1px solid rgba(0, 17, 39, 0.06);
  box-sizing: border-box;
  box-shadow: 0 4px 60px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  min-height: 179px;
  width: 100%;
}

.account-details-card {
  background: white;
  border: 1px solid rgba(0, 17, 39, 0.06);
  box-sizing: border-box;
  box-shadow: 0 4px 60px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  min-height: 179px;
  width: 638px;

  @media screen and (max-width: 820px) {
    //padding: 2rem 0.5rem !important;
    width: 100%;
    border: none !important;
  }

}

.change-password-card {
  background: white;
  border: 1px solid rgba(0, 17, 39, 0.06);
  box-sizing: border-box;
  box-shadow: 0 4px 60px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  min-height: 179px;
  width: 638px;
}

.deactivate-account-card {
  background: white;
  border: 1px solid rgba(0, 17, 39, 0.06);
  box-sizing: border-box;
  box-shadow: 0 4px 60px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  min-height: 179px;
  width: 1200px;
}

.card-inner {
  background: #fdfffc;
  border: 1px solid rgba(0, 17, 39, 0.06);
  box-sizing: border-box;
  box-shadow: 0 4px 60px rgba(0, 0, 0, 0.04);
  min-height: 89px;
}

.card-header {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: rgba(29, 38, 45, 0.83);
}
.link {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #004aad;
  flex-wrap: wrap;
  flex-direction: row;
}

.v-application--is-ltr
  .v-tabs--align-with-title
  > .v-tabs-bar:not(.v-tabs-bar--show-arrows):not(.v-slide-group--is-overflowing)
  > .v-slide-group__wrapper
  > .v-tabs-bar__content
  > .v-tab:first-child,
.v-application--is-ltr
  .v-tabs--align-with-title
  > .v-tabs-bar:not(.v-tabs-bar--show-arrows):not(.v-slide-group--is-overflowing)
  > .v-slide-group__wrapper
  > .v-tabs-bar__content
  > .v-tabs-slider-wrapper
  + .v-tab {
  margin-left: 0 !important;
}

.setting {
  min-height: 85vh;
}

::v-deep .theme--light.v-tabs-items {
  background: none !important;
}

::v-deep .theme--light.v-tabs > .v-tabs-bar {
  background: none !important;
}

.color-picker {
  height: 84px;
  width: 84px;
  padding: 20px 05px;
  // background: blue;
  border-radius: 100%;
  cursor: pointer;
}

.view-cert {
  height: 200px;
  width: auto;
  object-fit: contain;
}

.button-logo-input {
  display: flex;
  position: relative;
  border-radius: 100%;
  align-items: center;
  height: 80px;
  width: 80px;
  justify-content: center;
  border: 1px solid rgba(189, 189, 189, 0.3);
}
.button-logo-input .button-logo-img {
  width: fit-content;
  height: 100%;
  border-radius: 100%;
}

.button-logo-input .button-logo-input-img {
  width: 24.19px;
  height: 18.72px;
}

.err {
  font-size: 12px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  color: red;
}
</style>